export const calculateMonthlyOperations = (data, existingOperations): number => {
  const now = new Date();
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).getTime();

  const newOperations = data.add_points_operations || [];

  const operationsMap = new Map();
  [...existingOperations, ...newOperations].forEach(op => {
    if (op.id && !operationsMap.has(op.id)) {
      operationsMap.set(op.id, op);
    }
  });

  const allOperations = Array.from(operationsMap.values());
  const monthlyOps = allOperations.filter(op => {
    return op.status === 1 && op.created_at >= startOfMonth;
  });

  return monthlyOps.length;
};
