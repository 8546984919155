import { ISyncSuccessResponse } from "../../../types";
import { CashierWebDB } from "../../../db/CashierWebDB";
import { put, select } from "redux-saga/effects";
import { selectDB } from "../../selectors";
import { syncAppSuccess, storeStore } from "../../actions";
import {
  IRedeemGiftInboxOperation,
  IRedeemRewardOperation,
  ICashier,
  IAddPointsOperation
} from "../../../data-model/types";
import { getAccounts } from "../../actions/get-accounts-actions";
import Dexie from "dexie";
import { notify } from "react-notify-toast";
import i18n from "../../../i18n/i18n";
import * as Strings from "../../../i18n/strings/ManagePoints";
import { MANAGE_POINTS } from "../../../i18n/strings/App";
import { calculateMonthlyOperations } from "../../../../Utils/calculateMonthlyOperations";
class TableMetaData<T> {
  operation: string;
  table: Dexie.Table<T, string>;
  constructor(operation: string, table: Dexie.Table<T, string>) {
    this.operation = operation;
    this.table = table;
  }
}

// const handleFailedOperations = (failedOperations: IFailedActivity[]): any[] => {
//   return failedOperations.map(op => {
//     const { data, message, timestamp } = op;
//     return {
//       countryCode: data.countryCode,
//       created_at: timestamp,
//       price: data.receipt,
//       id: data.id,
//       receipt_code: data.receipt_code,
//       phoneNumber: data.phoneNumber,
//       customer: {
//         phone_number: data.phoneNumber
//       },
//       failed: 1,
//       message
//     };
//   });
// };

const handleGiftInboxGifts = (
  giftInboxRedemptions: IRedeemGiftInboxOperation[]
): IRedeemRewardOperation[] => {
  return giftInboxRedemptions.map(item => ({
    ...item,
    item: item.gift
  }));
};

export default function* handleSyncResponse(data: ISyncSuccessResponse) {
  const db: CashierWebDB = yield select(selectDB);

  const enqueueKey = {
    store: new TableMetaData("put", db.Store),
    branches: new TableMetaData("bulkPut", db.Branch),
    assigned_branches: new TableMetaData("bulkPut", db.AssignedBranch),
    categories: new TableMetaData("bulkPut", db.Category),
    items: new TableMetaData("bulkPut", db.StoreItem),
    cashiers: new TableMetaData("bulkPut", db.Cashier),
    customers: new TableMetaData("bulkPut", db.Customer),
    add_points_operations: new TableMetaData("bulkPut", db.AddPointsOperation),
    redeem_reward_operations: new TableMetaData(
      "bulkPut",
      db.RedeemRewardOperation
    ),
    failed_activities: new TableMetaData("bulkPut", db.AddPointsOperation),
    gift_operations: new TableMetaData("bulkPut", db.RedeemRewardOperation)
  };

  // Get existing operations and current cashier
  const existingOperations: IAddPointsOperation[] =
    yield db.AddPointsOperation.toArray();
  const cashiers: ICashier[] = yield db.Cashier.toArray();
  const currentCashier = cashiers[0];

  const fillDB = () =>
    Promise.all(
      Object.keys(enqueueKey).map(key => {
        const keyMetadata = enqueueKey[key];
        let value = data[key];

        if (value) {
          switch (key) {
            case "cashiers":
              const cashiers = Array.isArray(value)
                ? value
                : data.cashier
                ? [data.cashier]
                : [];

              const updatedCashiers = cashiers
                .filter(a => a.id)
                .map(cashier => {
                  const count = calculateMonthlyOperations(
                    data,
                    existingOperations
                  );
                  return {
                    ...cashier,
                    monthlyOperationsCount: count
                  };
                });

              return keyMetadata.table[keyMetadata.operation](updatedCashiers);

            case "failed_activities":
              const isPhoneValidationError = (value?.[0]?.message || "")
                .toLowerCase()
                .includes("validation.phone");
              const phoneNumber = value?.[0]?.data?.phoneNumber || "";

              notify.show(
                isPhoneValidationError
                  ? i18n.t(Strings.phoneErrorMessage, {
                      ns: MANAGE_POINTS,
                      phone: `: ${phoneNumber}`
                    })
                  : i18n.t(Strings.somethingWentWrong),
                "error"
              );
              return;

            case "gift_operations":
              return keyMetadata.table[keyMetadata.operation](
                handleGiftInboxGifts(value)
              );

            default:
              return keyMetadata.table[keyMetadata.operation](value);
          }
        }
      })
    );

  if (data.store) {
    yield put(storeStore(data.store));
  }

  if (data.cashier) {
    const count = calculateMonthlyOperations(data, existingOperations);
    const updatedCashier = {
      ...data.cashier,
      monthlyOperationsCount: count
    };
    yield db.Cashier.put(updatedCashier);
  } else if (currentCashier) {
    const count = calculateMonthlyOperations(data, existingOperations);
    const updatedCashier = {
      ...currentCashier,
      monthlyOperationsCount: count
    };
    yield db.Cashier.put(updatedCashier);
  }

  yield fillDB();
  yield put(
    syncAppSuccess({
      last_updated_time: data.last_updated_time
    })
  );

  yield put(getAccounts());
}
